window.onload = function () {
  // 禁止f12
  document.onkeydown = function () {
    if (window.event && window.event.keyCode == 123) {
      console.log(1111);
      event.keyCode = 0;
      event.returnValue = false;
    }
    // if (window.event && window.event.keyCode == 13) {
    //   window.event.keyCode = 505;
    // }
    // if (window.event && window.event.keyCode == 8) {
    //   window.event.returnValue = false;
    // }
  };
  // 禁止右键
  document.oncontextmenu = function (event) {
    if (window.event) {
      event = window.event;
    }
    try {
      var the = event.srcElement;
      if (
        !(
          (the.tagName == "INPUT" && the.type.toLowerCase() == "text") ||
          the.tagName == "TEXTAREA"
        )
      ) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };
  // 禁止粘贴
  document.onpaste = function (event) {
    if (window.event) {
      event = window.event;
    }
    try {
      var the = event.srcElement;
      if (
        !(
          (the.tagName == "INPUT" && the.type.toLowerCase() == "text") ||
          the.tagName == "TEXTAREA"
        )
      ) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };
  // 禁止复制
  document.oncopy = function (event) {
    if (window.event) {
      event = window.event;
    }
    try {
      var the = event.srcElement;
      if (
        !(
          (the.tagName == "INPUT" && the.type.toLowerCase() == "text") ||
          the.tagName == "TEXTAREA"
        )
      ) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };
  // 禁止剪切
  document.oncut = function (event) {
    if (window.event) {
      event = window.event;
    }
    try {
      var the = event.srcElement;
      if (
        !(
          (the.tagName == "INPUT" && the.type.toLowerCase() == "text") ||
          the.tagName == "TEXTAREA"
        )
      ) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };
  // 禁止选中
  document.onselectstart = function (event) {
    if (window.event) {
      event = window.event;
    }
    try {
      var the = event.srcElement;
      if (
        !(
          (the.tagName == "INPUT" && the.type.toLowerCase() == "text") ||
          the.tagName == "TEXTAREA"
        )
      ) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };
};
