import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import '@/utils/prohibit'
// 引入flexible插件
import 'lib-flexible/flexible'

// 引入字体图标
import '@/assets/font/iconfont.css'
import '@/assets/css/reset.css'
// 引入element Ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// 在Vue项目的入口文件中（如main.js）引入组件库
import AmapVue from '@amap/amap-vue'
Vue.use(Vuex)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
AmapVue.config.key = '53d1acaa4bda08751fe1d0178526837a'
Vue.use(AmapVue)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
