import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomePage'),

  },
  {
    path: '/about',
    name: 'aboutus',
    component: () => import('@/views/AboutMe')
  },
  {
    path: '/contact',
    name: 'contactus',
    component: () => import('@/views/ContactUs')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/ProductDetails')
  },
  {
    path: '/productcenter',
    name: 'productcenter',
    component: () => import('@/views/ProductCenter')
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import('@/views/TechnicAlrticles')
  },
  {
    path: '/engineering',
    name: 'engineering',
    component: () => import('@/views/EngineeringCase')
  },
  {
    path: "*",
      name: "NotFound",
      component: () => import("@/views/404"),
  }
]

// router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
// })

const router = new VueRouter({
  routes
})


export default router
